<!-- 进修招聘  培训内容管理 邓 -->
<template>
  <div style="margin: -15px -15px; background-color: #f0f2f5">
    <div class="datePicker">
      <div>
        操作日期：
        <a-date-picker
          v-decorator="['year']"
          placeholder="请输入年份"
          format="YYYY"
          class="input"
        />
      </div>
      <div>
        进修标题：
        <a-input style="width: 200px" placeholder="请输入"></a-input>
      </div>
      <div>
        <a-radio-group v-model="status" @change="searchOk">
          状态：
          <a-radio-button value="0"> 未发布</a-radio-button>
          <a-radio-button value="1"> 已发布 </a-radio-button>
        </a-radio-group>
      </div>
      <div>
        <a-button type="primary">查询</a-button>
        <a-button style="margin-left: 10px">重置</a-button>
      </div>
    </div>

    <div class="body">
      <a-button type="primary" style="width: 70px">新增</a-button>
      <div style="width: 100%; margin: auto; margin-top: 20px">
        <a-table :columns="columns" :scroll="{ x: 1800 }"> </a-table>
      </div>
    </div>

    <a-drawer
      title="新增"
      :width="500"
      visible="true"
      :body-style="{ paddingBottom: '80px' }"
    >
      <a-form-model :label-col="labelColLive" :wrapper-col="wrapperCol">
        <a-form-model-item label="培训标题">
          <a-input></a-input>
        </a-form-model-item>
        <a-form-model-item label="培训周期">
          <a-input></a-input>
        </a-form-model-item>
        <a-form-model-item label="费用">
          <a-input></a-input>
        </a-form-model-item>
        <a-form-model-item label="住宿">
          <a-input></a-input>
        </a-form-model-item>
        <a-form-model-item label="标签">
          <a-input></a-input>
        </a-form-model-item>
        <a-form-model-item label="省份/城市">
          <a-input></a-input>
        </a-form-model-item>
        <a-form-model-item label="医院">
          <a-input></a-input>
        </a-form-model-item>
        <a-form-model-item label="承办科室">
          <a-input></a-input>
        </a-form-model-item>
      </a-form-model>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button> 取消 </a-button>
        <a-button style="margin-left: 10px" type="primary"> 确定 </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
const columns = [
  {
    title: "培训标题",
    dataIndex: "person",
    width: "23%",
  },
  {
    title: "标记",
    dataIndex: "person",
    width: "15%",
  },
  {
    title: "医院",
    dataIndex: "person",
    width: "14%",
  },
  {
    title: "培训时长",
    dataIndex: "person",
    width: "10%",
  },
  {
    title: "报名时间",
    dataIndex: "person",
    width: "17%",
  },
  {
    title: "创建人",
    dataIndex: "person",
    width: "7%",
  },
  {
    title: "创建时间",
    dataIndex: "person",
    width: "14%",
  },
  {
    title: "是否发布",
    dataIndex: "person",
    width: "7%",
  },
  {
    title: "操作",
    key: "action",
    width: 200,
    fixed: "right",
  },
];
export default {
  data() {
    return {
      columns,
      labelColLive: { span: 5 },
      wrapperCol: { span: 17 },
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.datePicker {
  background-color: #fff;
  width: 100%;
  height: 64px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  position: relative;
}
.body {
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-flow: column;
}
// ::v-deep .ant-table-thead > tr > th {
//   font-weight: bold;
// }
</style>
